<template>
  <section class="one">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="one__inner">
          <h1 class="one__title">{{ lang === 'kz' ? 'Диспетчерге арналған веб-қолданба' : 'Веб-приложение для диспетчера' }}</h1>
          <p class="one__text">{{ lang === 'kz' ? 'Құрамды бағалау бойынша жаңа кесте құрау программаға қосылатын барлық паралардың машықтығын ескеріңіз' : 'Теперь программа составит грамотное расписание с учётом сложности пар за вас' }}</p>
          <button class="one__btn btn" @click="scrollToSection('disp')">
            <p>{{ lang === 'kz' ? 'Құрау' : 'Сгенерировать' }}</p>
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="disp" id="disp">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="disp__inner">
          <h2 class="disp__title">{{ lang === 'kz' ? 'Сағаттарды толтырыңыз' : 'Заполните количество часов' }}</h2>
          <p class="disp__text">{{ lang === 'kz' ? 'Программа кестені құру үшін - әр бір пәннің екі айға көрсетілген жұмыс жүктегін жазыңыз' : 'Чтобы программа смогла сгенерировать расписание - запишите, какая нагрузка у каждого предмета на пол года' }}</p>
          <form class="disp__subjects" @submit.prevent="fetchDataInBackend()">
            <div class="disp__subjects-table">
              <div class="disp__subjects-list">
                <div class="disp__subject" v-for="subject in subjects" :key="subject.name">
                  <p class="disp__subject-name">{{ subjectNames[lang][subject.name] }}</p>
                </div>
              </div>
              <div class="disp__subjects-hours">
                <div class="disp__subject-hour" v-for="subject in subjects" :key="subject.name">
                  <input class="disp__subject-input" v-model="subject.hours">
                </div>
              </div>
            </div>
            <p class="disp__msg">{{ lang === 'kz' ? 'Нұсқаулық сәтті сақталды' : 'Нагрузка успешно сохранена' }}</p>
            <p class="disp__error">{{ lang === 'kz' ? 'Толығырақ жазылған мәліметтерді тексеріңіз' : 'Проверьте корректность заполненных данных' }}</p>
            <button class="disp__subjects-btn btn" type="submit">{{ lang === 'kz' ? 'Сағаттарды өзгерту' : 'Изменить кол - во часов' }}</button>
          </form>
          <h2 class="disp__title">{{ lang === 'kz' ? 'Бұрыңғы мәліметтер бойынша кесте' : 'Расписание по ранее загруженным данным' }}</h2>
          <label class="disp__select">
            <p class="disp__select-text">{{ lang === 'kz' ? 'МЖМБС және ҚР БҒМ стандарттарын ұстану' : 'Следовать стандартам ГОСО и МОН РК' }}</p>
            <input class="disp__select-input" type="checkbox" v-model="gos" @change="handleCheckboxChange">
            <span class="disp__select-custom"></span>
          </label>
          <div class="disp__subjects-group">
            <p>
              {{ lang === 'kz' ? 'Таңдаулы тапсырманы' : 'Выбранная неделя' }}:
            </p>
            <div class="disp__subjects-week">
              <button @click="weekMinusBtn">←</button>
              <p :key="selectedWeekNum">
                {{ selectedWeekNum + 1 }}
              </p>
              <button @click="weekPlusBtn">→</button>
            </div>
          </div>
          <div class="disp__tables">
            <div class="disp__table--nums">
              <p class="disp__table-name">{{ lang === 'kz' ? 'К/Т' : 'Д/Н' }}</p>
              <div class="disp__table-list--nums">
                <p class="disp__table-item" v-for="(num, index) in 5" :key="index">{{ num }}</p>
              </div>
            </div>

            <div v-for="day in days" :key="day.id" class="disp__table">
              <p class="disp__table-name">{{ dayNames[lang][day.id] }}</p>
              <div class="disp__table-list">
                <p v-if="selectedWeek.days && selectedWeek.days[day.id - 1].themes && selectedWeek.days[day.id - 1].themes.length"
                  v-for="theme in selectedWeek.days[day.id - 1].themes" :key="theme.name + theme.date + theme.subject"
                  class="disp__table-item">
                  {{ subjectNames[lang][theme.subject] }}&nbsp;
                  <!-- {{ theme.date }}&nbsp; -->
                  <!-- {{ theme.complexity }} -->
                </p>
                <p v-else class="disp__table-item">{{ lang === 'kz' ? 'Деректер жүктелуде' : 'Данные загружаются' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


import axios, { all } from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      weeks: [],
      weeksNum: 17,
      themesOfWeek: 0,
      selectedWeekNum: 0,
      selectedWeek: {},
      forTable: 1,
      allThemes: [],
      gos: false,
      subjectNames: {
        ru: {
          'Физическая культура': 'Физическая культура',
          'Физика': 'Физика',
          'Математика': 'Математика',
          'Биология': 'Биология',
          'Информатика': 'Информатика',
          'Иностранный язык': 'Иностранный язык',
          'История Казахстана': 'История Казахстана',
          'НВТП': 'НВТП',
          'Казахский язык и литература': 'Казахский язык и литература',
          'Русский язык': 'Русский язык',
          'Русская литература': 'Русская литература'
        },
        kz: {
          'Физическая культура': 'Физикалық күнбіржи',
          'Физика': 'Физика',
          'Математика': 'Математика',
          'Биология': 'Биология',
          'Информатика': 'Информатика',
          'Иностранный язык': 'Шетел тілі',
          'История Казахстана': 'Қазақстан тарихы',
          'НВТП': 'НВТП',
          'Казахский язык и литература': 'Қазақ тілі және әдебиеті',
          'Русский язык': 'Орыс тілі',
          'Русская литература': 'Орыс әдебиеті'
        }
      },
      subjects: [
        { name: 'Физическая культура', hours: 0 },
        { name: 'Физика', hours: 0 },
        { name: 'Математика', hours: 0 },
        { name: 'Биология', hours: 0 },
        { name: 'Информатика', hours: 0 },
        { name: 'Иностранный язык', hours: 0 },
        { name: 'История Казахстана', hours: 0 },
        { name: 'НВТП', hours: 0 },
        { name: 'Казахский язык и литература', hours: 0 },
        { name: 'Русский язык', hours: 0 },
        { name: 'Русская литература', hours: 0 },
      ],
      dayNames: {
        ru: {
          1: 'Понедельник',
          2: 'Вторник',
          3: 'Среда',
          4: 'Четверг',
          5: 'Пятница'
        },
        kz: {
          1: 'Дүйсенбі',
          2: 'Сейсенбі',
          3: 'Сәрсенбі',
          4: 'Бейсенбі',
          5: 'Жұма'
        }
      },
      days: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 }
      ]
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang || 'kz';
    },
  },
  methods: {
    async fetchSubjectsData() {
      try {
        const response = await fetch('/api/getSubjectsData');
        const subjectsData = await response.json();


        this.subjects = subjectsData;
        let allHours = 0;
        this.subjects.forEach(item => {
          allHours += item.hours;
        });
        if (allHours !== 0) {
          this.themesOfWeek = allHours / 2 / this.weeksNum;

        }


      } catch (error) {
        console.error('Ошибка при получении данных о предметах:', error);
      }
    },
    weekPlusBtn() {
      if (this.selectedWeekNum <= this.weeksNum - 2) {
        this.selectedWeekNum++;
        this.refreshWeek();
      }
    },
    weekMinusBtn() {
      if (this.selectedWeekNum >= 1) {
        this.selectedWeekNum--;
        this.refreshWeek();
      }
    },
    refreshWeek() {

      if (this.weeks[this.selectedWeekNum]) {
        this.selectedWeek = this.weeks[this.selectedWeekNum];
        this.$nextTick(() => {
          this.updatePair();
        });
      } else {
        console.error('Неделя с номером', this.selectedWeekNum, 'не найдена.');
        this.selectedWeek = {};
      }

    },
    updatePair() {

      let pair = document.querySelectorAll('.disp__table-item');
      let tryChange = false;
      let selectedElement;
      pair.forEach(item => {
        item.addEventListener('click', function () {
          if (!tryChange) {

            selectedElement = item;
            tryChange = true;
            item.classList.add('selected');
          } else {

            if (selectedElement !== item) {

              let temp = item.innerHTML;
              item.innerHTML = selectedElement.innerHTML;
              selectedElement.innerHTML = temp;

              selectedElement.classList.remove('selected');
              tryChange = false;
            } else {

              selectedElement.classList.remove('selected');
              tryChange = false;
            }
          }

        });
      });
    },
    fetchDataInBackend() {
      let msg = document.querySelector('.disp__msg');
      let error = document.querySelector('.disp__error');


      let hasInvalidHours = this.subjects.some(item => {
        return (
          item.hours === null ||
          item.hours === '' ||
          isNaN(item.hours) ||
          item.hours % 2 !== 0
        );
      });

      if (hasInvalidHours) {
        error.classList.add('show');
        setTimeout(() => {
          error.classList.remove('show');
        }, 3000);
      } else {
        this.submitHours();
        msg.classList.add('show');
        setTimeout(() => {
          msg.classList.remove('show');
        }, 3000);
      }
    },

    async submitHours() {
      try {
        if (this.subjects.length > 0) {


          const dataToSend = this.subjects.map(item => ({ name: item.name, hours: item.hours }));


          const response = await axios.post('/api/submitHours', { subjects: dataToSend });



        }
      } catch (error) {
        console.error('Ошибка:', error);

      }
    },


    async fetchDataFromBackend() {
      try {

        const response = await fetch('/api/getAllData');
        if (!response.ok) {
          throw new Error('Ошибка получения данных с сервера');
        }
        const data = await response.json();


        this.allThemes = [];
        Object.keys(data).forEach((subject) => {
          this.allThemes = this.allThemes.concat(data[subject].map((theme) => ({ ...theme, subject })));
        });


        this.editWeeks();
        this.balanceWeeks();
        this.sortThemesByComplexityDescending();
        this.refreshWeek();
        this.calculateTotalComplexity();
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },

    editWeeks() {
      const themesBySubject = {};
      this.allThemes.forEach((theme) => {
        const subject = theme.subject;
        if (!themesBySubject[subject]) {
          themesBySubject[subject] = [];
        }
        themesBySubject[subject].push(theme);
      });


      const weeks = Array.from({ length: this.weeksNum }, () => ({
        days: Array.from({ length: 5 }, () => ({ themes: [] })),
      }));


      let themeOfWeeks = [];
      let themeOfWeek = [];
      for (let o = 0; o < this.weeksNum; o++) {
        for (const subject in themesBySubject) {
          const themeList = themesBySubject[subject];
          const themesPerWeek = themeList.length / this.weeksNum;

          const tempThemeList = Array.from({ length: themesPerWeek }, (_, index) => {
            const currentIndex = (index + Math.floor(o * themesPerWeek)) % themeList.length;
            return themeList[currentIndex];
          });
          tempThemeList.forEach(item => {
            themeOfWeek.push(item);
          });
        }
        themeOfWeeks.push(themeOfWeek);
        themeOfWeek = [];
      }
      for (let o = 0; o < this.weeksNum; o++) {
        let tempThemeList = themeOfWeeks[o];
        let tempThemeIndex = 0;


        for (let j = 0; j < 5; j++) {
          let day = weeks[o].days[j];


          while (day.themes.length < 4) {
            let theme = tempThemeList[tempThemeIndex];
            if (theme) {
              day.themes.push(theme);




              tempThemeIndex++;
            }
            else {
              break;
            }
          }
        }
      }

      const unusedThemes = [...this.allThemes];


      for (let week of weeks) {
        for (let day of week.days) {

          day.themes.forEach(theme => {
            const index = unusedThemes.findIndex(item => item === theme);
            if (index !== -1) {
              unusedThemes.splice(index, 1);
            }
          });
        }
      }


      const sortedUnusedThemes = unusedThemes.sort(() => Math.random() - 0.534324234);

      let themesNum = 0;
      for (let i = 0; i < this.weeksNum; i++) {
        let week = weeks[i];
        week.days.forEach(day => {
          themesNum += day.themes.length;
        });
        if (themesNum < this.themesOfWeek) {
          for (let l = 0; l < this.themesOfWeek - themesNum; l++) {
            week.days[4].themes.push(sortedUnusedThemes.shift());
          }
        }
        themesNum = 0;
      }


      const newWeeks = JSON.parse(JSON.stringify(weeks));


      const allThemesCopy = JSON.parse(JSON.stringify(this.allThemes));


      newWeeks.forEach((week, weekIndex) => {
        week.days.forEach((day, dayIndex) => {
          day.themes.forEach((theme, themeIndex) => {

            let matchingTheme = allThemesCopy.find(item => item.subject === theme.subject);
            if (matchingTheme) {
              day.themes[themeIndex] = matchingTheme;
              allThemesCopy.splice(allThemesCopy.indexOf(matchingTheme), 1);
            }
          });
        });
      });
      if (this.gos) {

        const allThemesCopy1 = JSON.parse(JSON.stringify(this.allThemes));

        newWeeks.forEach((week, weekIndex) => {
          week.days.forEach((day, dayIndex) => {
            day.themes.forEach((theme, themeIndex) => {
              const physicalThemesCount = week.days.reduce((count, day) => {
                return count + day.themes.filter(t => t.subject == 'Физическая культура').length;
              }, 0);

              let matchingTheme = allThemesCopy1.find(item => item.subject === theme.subject);
              if (matchingTheme) {

                if (physicalThemesCount < 3 && theme.subject === 'Физическая культура') {
                  day.themes[themeIndex] = matchingTheme;
                  allThemesCopy1.splice(allThemesCopy1.indexOf(matchingTheme), 1);
                } else if (theme.subject !== 'Физическая культура') {
                  day.themes[themeIndex] = matchingTheme;
                  allThemesCopy1.splice(allThemesCopy1.indexOf(matchingTheme), 1);
                } else {
                  day.themes[themeIndex] = {
                    subject: '',
                    date: '',
                    complexity: 0,
                    name: this.forTable
                  };
                  this.forTable++
                  allThemesCopy1.splice(allThemesCopy1.indexOf(matchingTheme), 1);
                }
              }
            });
          });
        });
      }

      newWeeks.forEach((week, weekIndex) => {

        week.days.forEach((day, dayIndex) => {

          while (day.themes.length < 5) {
            day.themes.push({
              subject: '',
              date: '',
              complexity: 0,
              name: this.forTable
            });
            this.forTable++
          }
        });
      });

      this.weeks = newWeeks;
    },
    balanceWeeks() {
      this.weeks.forEach(week => {
        let isBalanced = false;
        let attempt = 0;
        let diff = 2;
        while (!isBalanced) {
          attempt++;
          if (attempt > 300) {
            diff++;
            attempt = 0;
          }
          const dayComplexities = week.days.map(day =>
            day.themes ? day.themes.reduce((sum, theme) => sum + theme.complexity, 0) : 0
          );

          const maxComplexity = Math.max(...dayComplexities);
          const minComplexity = Math.min(...dayComplexities);

          if (maxComplexity - minComplexity < diff) {
            isBalanced = true;
            break;
          }

          const randomComplexDayIndex = Math.floor(Math.random() * week.days.length);
          const randomSimpleDayIndex = Math.floor(Math.random() * week.days.length);

          if (!week.days[randomComplexDayIndex].themes || !week.days[randomSimpleDayIndex].themes) {
            continue;
          }

          const randomComplexThemeIndex = Math.floor(Math.random() * week.days[randomComplexDayIndex].themes.length);
          const randomSimpleThemeIndex = Math.floor(Math.random() * week.days[randomSimpleDayIndex].themes.length);


          const originalComplexTheme = { ...week.days[randomComplexDayIndex].themes[randomComplexThemeIndex] };
          const originalSimpleTheme = { ...week.days[randomSimpleDayIndex].themes[randomSimpleThemeIndex] };


          [week.days[randomComplexDayIndex].themes[randomComplexThemeIndex], week.days[randomSimpleDayIndex].themes[randomSimpleThemeIndex]] =
            [originalSimpleTheme, originalComplexTheme];


          if (!this.areAllThemesInOrder(week)) {

            [week.days[randomComplexDayIndex].themes[randomComplexThemeIndex], week.days[randomSimpleDayIndex].themes[randomSimpleThemeIndex]] =
              [originalComplexTheme, originalSimpleTheme];
          }
        }
      });
      this.calculateTotalComplexity();
    },


    areAllThemesInOrder(week) {

      const allThemes = week.days.flatMap(day => day.themes.filter(theme => theme.date > 0));


      const subjects = {};
      allThemes.forEach(theme => {
        if (!subjects[theme.subject]) {
          subjects[theme.subject] = [];
        }
        subjects[theme.subject].push(theme);
      });



      Object.keys(subjects).forEach(key => {
        if (subjects[key].length <= 1) {
          delete subjects[key];
        }
      });


      return Object.values(subjects).every(subjectThemes => {
        for (let i = 0; i < subjectThemes.length - 1; i++) {
          if (subjectThemes[i].date >= subjectThemes[i + 1].date) {
            return false;
          }
        }
        return true;
      });
    },
    sortThemesByComplexityDescending() {

      this.weeks.forEach(week => {

        week.days.forEach(day => {

          if (day.themes) {
            day.themes.sort((a, b) => b.complexity - a.complexity);
          }
        });
      });
    },
    handleCheckboxChange() {
      this.editWeeks();
      this.refreshWeek();
      this.balanceWeeks();
      this.sortThemesByComplexityDescending();
      this.refreshWeek();

    },
    calculateTotalComplexity() {
      let maxDif = 0;

      for (let weekNum = 0; weekNum < this.weeks.length; weekNum++) {

        const week = this.weeks[weekNum];
        let maxComplexity = 0;
        let minComplexity = Infinity;

        for (let dayIndex = 0; dayIndex < week.days.length; dayIndex++) {
          const day = week.days[dayIndex];


          if (Array.isArray(day.themes)) {
            const totalComplexity = day.themes.reduce((sum, theme) => sum + theme.complexity, 0);



            if (totalComplexity > maxComplexity) {
              maxComplexity = totalComplexity;
            }
            if (totalComplexity < minComplexity) {
              minComplexity = totalComplexity;
            }
          } else {
            console.error(`  День ${dayIndex + 1}: Ошибка - themes не является массивом.`);
          }
        }


        const diff = maxComplexity - minComplexity;


        if (diff > maxDif) {
          maxDif = diff;
        }
      }




      if (maxDif >= 7) {
        this.editWeeks();
        this.balanceWeeks();
      }

    },

    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    this.fetchSubjectsData();
    this.fetchDataFromBackend();

  }
};
</script>