<template>
  <footer class="footer">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="footer__inner">
          <p class="footer__copy">{{ lang === 'kz' ? 'VTK@2024' : 'VTK@2024' }}</p>
          <a class="footer__conf" :href="lang === 'kz' ? '/kz/' : '/ru/'">{{ lang === 'kz' ? 'Құпиялылық саясаты' : 'Политика Конфиденциальности' }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footers',
  computed: {
    lang() {
      return this.$route.params.lang || 'kz';
    }
  }
}
</script>
