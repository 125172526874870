<template>
  <header class="header">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="header__inner">
          
          <div class="header__menu">
            <nav class="header__menu-list">
              <li class="header__menu-item">
              <p class="header__menu-text">{{ loggedInAs }}</p>
            </li>
            <button class="header__menu-lang" @click="toggleLanguage">
              {{ lang === 'kz' ? 'RU' : 'KZ' }}
            </button>
            <div class="header__menu-box">
              <li class="header__menu-item" v-if="userRole">
                <p class="header__menu-title">{{ lang === 'kz' ? 'Кіру керек' : 'Войти как' }} &nbsp;</p>
              </li>
              <li class="header__menu-item prepod-item" v-if="userRole === 'Преподаватель'">
                <a :href="lang === 'kz' ? '/kz/' : '/ru/'">
                  {{ lang === 'kz' ? 'Мұғалім' : 'Преподаватель' }}
                </a>
              </li>
              <li class="header__menu-item disp-item" v-if="userRole === 'Диспетчер'">
                <a :href="lang === 'kz' ? '/kz/disp' : '/ru/disp'">
                  Диспетчер
                </a>
              </li>
            </div>
          </nav>
        </div>
          <div class="header__menu-btn">
            <span>{{ lang === 'kz' ? 'Мәзір' : 'Меню' }}</span>
            <div class="header__burger">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Headers',
  mounted() {
    let menu = document.querySelector(".header__menu");
    let btn = document.querySelector(".header__menu-btn");

    btn.addEventListener('click', () => {
      menu.classList.toggle("active");
      btn.classList.toggle('active');
    });

  },
  computed: {
    lang() {
      return this.$route.params.lang || 'kz';
    },
    loggedInAs() {
      // Определяем текст "Вы вошли как" в зависимости от языка и страницы
      if (this.$route.path.includes('/disp')) {
        return this.lang === 'kz' ? 'Сіз диспетчер ретінде кірдіңіз' : 'Вы вошли как диспетчер';
      } else {
        return this.lang === 'kz' ? 'Сіз мұғалім ретінде кірдіңіз' : 'Вы вошли как преподаватель';
      }
    },
    userRole() {
      if (this.$route.path === '/disp' || this.$route.path === '/ru/disp' || this.$route.path === '/kz/disp') {
        return 'Преподаватель';
      } else {
        return 'Диспетчер';
      }
    }
  },
  methods: {
    toggleLanguage() {
      let newLang = this.lang === 'kz' ? 'ru' : 'kz';

      // Формируем новый путь
      let newPath = '';

      // Проверяем текущий путь и изменяем его в соответствии с новым языком
      switch (this.lang) {
        case 'kz':
          if (this.$route.path === '/' || this.$route.path === '/disp') {
            newPath = `/${newLang}${this.$route.path}`;
          } else if (this.$route.path.includes('/kz/')) {
            newPath = this.$route.path.replace('/kz/', `/${newLang}/`);
          }
          break;
        case 'ru':
          if (this.$route.path.includes('/ru/')) {
            newPath = this.$route.path.replace('/ru/', `/${newLang}/`);
          }
          break;
        default:
          newPath = `/${newLang}${this.$route.path}`;
      }

      // Используем $router.replace() для изменения пути
      this.$router.replace({ path: newPath });
    }
  }
}
</script>