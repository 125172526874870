<template>
  <section class="one">
    <div class="container">
      <div class="container__elem container__elem--12">
        <div class="one__inner">
          <h1 class="one__title">{{ lang === 'kz' ? 'Web-ақпараттық жүйелер' : 'Веб-приложение для преподавателей' }}
          </h1>
          <p class="one__text">{{ lang === 'kz' ? 'Диспетчерге дұрыс кесте құру мүмкіндігінен бас тартпа, параға ақпаратты толтырыңыз' : 'Чтобы диспетчер мог составить грамотное расписание с учётом сложности пар заполните расписание пар' }}</p>
          <button class="one__btn btn" @click="scrollToSection('prepod')">
            <p>{{ lang === 'kz' ? 'Толтыру' : 'Заполнить' }}</p>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="prepod" id="prepod">
    <div class="container">
      <div class="container__elem container__elem--12">
        <form class="prepod__inner" @submit.prevent="submitForm()">
          <div class="prepod__subject">
            <h1 class="prepod__subject-title">{{ lang === 'kz' ? 'Пәнді таңдау' : 'Выбор предмета' }}</h1>
            <div class="prepod__subject-info">
              <p class="prepod__subject-text">{{ lang === 'kz' ? 'Сіздің пәніңіз:&nbsp;' : 'Ваш предмет:&nbsp;' }}</p>
              <input class="prepod__subject-selected" v-model="formData.subjectDisplayed"
                :placeholder="lang === 'kz' ? 'Таңдалмаған' : 'He выбрано'" required>
            </div>
            <button class="prepod__subject-btn btn">
              <p>{{ lang === 'kz' ? 'Пәндер тізімін көрсету' : 'Показать список предметов' }}</p>
            </button>
            <ul class="prepod__subject-list">
              <li class="prepod__subject-item" v-for="subject in subjects" @click="refreshTheme(subject.name)"
                :key="subject.name">
                {{ subjectNames[lang][subject.name] }}
              </li>
            </ul>
          </div>
          <div class="prepod__themes">
            <h2 class="prepod__themes-title">{{ lang === 'kz' ? 'Тақырыптарды толтырыңыз' : 'Заполните темы' }}</h2>
            <div class="prepod__themes-items">
              <div class="prepod__themes-item">
                <p class="prepod__themes-text">{{ lang === 'kz' ? 'Нөмір' : 'Номер' }}</p>
                <p class="prepod__themes-text">{{ lang === 'kz' ? 'Тақырып' : 'Тема' }}</p>
                <p class="prepod__themes-text">{{ lang === 'kz' ? 'Қиындық' : 'Сложность' }}</p>
              </div>
              <div class="prepod__themes-item" v-for="(item, index) in   formData.themes  " :key="index">
                <p class="prepod__themes-descr">{{ lang === 'kz' ? 'Нөмір' : 'Номер' }}</p>
                <input class="prepod__themes-date" type="text" v-model="item.date" @input="updateDate($event, index)"
                  readonly>
                <p class="prepod__themes-descr">{{ lang === 'kz' ? 'Тақырып' : 'Тема' }}</p>
                <input class="prepod__themes-name" type="text" v-model="item.theme">
                <div class="prepod__complexity">
                  <p class="prepod__themes-descr">{{ lang === 'kz' ? 'Қиындық' : 'Сложность' }}</p>
                  <input class="prepod__themes-complexity" readonly type="text"
                    :placeholder="lang === 'kz' ? 'Басу' : 'Нажмите сюда'" v-model="item.complexity" value="5">

                  <div class="prepod__complexity-list active">
                    <p class="prepod__complexity-item" v-for="  num   in   10  ">{{ num }}</p>
                  </div>
                </div>
              </div>
            </div>
            <p class="disp__msg">{{ lang === 'kz' ? 'Нагрузка сәтті сақталды' : 'Нагрузка успешно сохранена' }}</p>
            <p class="disp__error">{{ lang === 'kz' ? 'Қиындықтарды барлық толтыру қажет' : 'Похоже не все сложности заполнены' }}</p>
            <div class="prepod__themes-btns">
              <button class="prepod__themes-btn btn" type="submit">{{ lang === 'kz' ? 'Тақырыптарды жіберу' :
                'Отправить список тем' }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomeView',
  data() {
    return {
      subjects: [],
      subjectNames: {
        ru: {
          'Физическая культура': 'Физическая культура',
          'Физика': 'Физика',
          'Математика': 'Математика',
          'Биология': 'Биология',
          'Информатика': 'Информатика',
          'Иностранный язык': 'Иностранный язык',
          'История Казахстана': 'История Казахстана',
          'НВТП': 'НВТП',
          'Казахский язык и литература': 'Казахский язык и литература',
          'Русский язык': 'Русский язык',
          'Русская литература': 'Русская литература'
        },
        kz: {
          'Физическая культура': 'Физикалық күнбіржи',
          'Физика': 'Физика',
          'Математика': 'Математика',
          'Биология': 'Биология',
          'Информатика': 'Информатика',
          'Иностранный язык': 'Шетел тілі',
          'История Казахстана': 'Қазақстан тарихы',
          'НВТП': 'НВТП',
          'Казахский язык и литература': 'Қазақ тілі және әдебиеті',
          'Русский язык': 'Орыс тілі',
          'Русская литература': 'Орыс әдебиеті'
        }
      },

      formData: {
        subject: '',
        subjectDisplayed: '',
        themes: Array.from({ length: 1 }, () => ({
          date: '',
          theme: '',
          complexity: ''
        })),
      },
    };
  },
  watch: {

    lang: {
      handler(newLang) {

        this.updateSubjectDisplayed();
      },

      deep: true
    }
  },
  methods: {
    async fetchSubjectsData() {
      try {
        const response = await fetch('/api/getSubjectsData');
        const subjectsData = await response.json();


        this.subjects = subjectsData;



      } catch (error) {
        console.error('Ошибка при получении данных о предметах:', error);
      }
    },
    updateSubjectDisplayed() {

      const translatedSubjectName = this.subjectNames[this.lang][this.formData.subject];


      this.formData.subjectDisplayed = translatedSubjectName;
    },
    refreshTheme(selectedSubjectName) {
      const selectedSubject = this.subjects.find(subject => subject.name === selectedSubjectName);
      let list = document.querySelector('.prepod__subject-list');
      if (selectedSubject) {
        list.classList.remove('active');
        selectedSubject.hours = parseInt(selectedSubject.hours, 10);


        const translatedSubjectName = this.subjectNames[this.lang][selectedSubjectName];


        this.formData.subject = selectedSubjectName;


        this.formData.subjectDisplayed = translatedSubjectName;


        this.formData.themes = Array.from({ length: selectedSubject.hours / 2 }, (_, index) => ({
          date: (index + 1).toString(),
          theme: '',
          complexity: ''
        }));
        setTimeout(() => {
          this.refreshComplexity();
        }, 100);
      }
    },
    async submitForm() {

      let lists = document.querySelectorAll('.prepod__complexity-list');
      let flag = true;
      lists.forEach(list => {
        if (list.classList.contains('active')) {
          flag = false;
        }
      });

      if (!flag) {
        let err = document.querySelector('.disp__error');
        err.classList.add('show');
        setTimeout(() => {
          err.classList.remove('show');
        }, 3000);
        return;
      }
      console.log('Отправляемые данные:', this.formData);

      try {
        const response = await fetch('/api/saveFormData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.formData)
        });

        const responseData = await response.json();
        console.log(responseData);

        let msg = document.querySelector('.disp__msg');
        msg.classList.add('show');
        setTimeout(() => {
          msg.classList.remove('show');
        }, 3000);
      } catch (error) {
        console.error('Ошибка:', error);
      }
    },


    refreshComplexity() {
      let complexity = document.querySelectorAll('.prepod__complexity');
      complexity.forEach((item, index) => {
        let input = item.querySelector('input');
        let complexityList = item.querySelector('.prepod__complexity-list');
        let complexityItem = item.querySelectorAll('.prepod__complexity-item');
        complexityList.classList.add('active');
        if (input.value) {
          complexityList.classList.remove('active');
        }
        input.addEventListener("click", () => {
          complexityList.classList.toggle("active");
        });
        complexityItem.forEach(item => {
          item.addEventListener('click', () => {
            let newItem = item.innerHTML;
            input.value = newItem;
            this.formData.themes[index].complexity = input.value;
            complexityList.classList.remove("active");
          });
        });
      });
    },

    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },

  },
  computed: {
    lang() {
      return this.$route.params.lang || 'kz';
    },
  },
  mounted() {
    this.fetchSubjectsData();

    let list = document.querySelector(".prepod__subject-list");
    let btn = document.querySelector(".prepod__subject-btn")
    let selectedItem = document.querySelector(".prepod__subject-selected")
    let listItem = document.querySelectorAll(".prepod__subject-item")
    this.refreshTheme();
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      list.classList.toggle('active');
    })
    function removeAll() {
      listItem.forEach(item => {
        item.classList.remove('active');
      });
    }
    listItem.forEach(item => {
      item.addEventListener("click", () => {
        removeAll();
        item.classList.add('active');
        let newSubject = item.innerHTML;
        selectedItem.value = newSubject;
        this.formData.subject = selectedItem.value;
        list.classList.remove('active');
      });
    });

    let inputs = document.querySelectorAll('.prepod__themes-date');
    let currentDate = 1;

    inputs.forEach((input, index) => {
      input.value = currentDate;
      currentDate++;
      this.formData.themes[index].date = input.value;
    });
  }
};
</script>
